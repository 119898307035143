import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';

const data = [
    { name: 'Aprendizagem', value: 40 },
    { name: 'Saúde', value: 30 },
    { name: 'Comportamento', value: 20 },
    { name: 'Família', value: 10 },
  ];
  

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'gray'];

const MyPieChart = ({data, title = "Chart"}) => {
  // Calculate total value
  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  return (
    <div className="container my-3">
      <div className="row align-items-center">
        {/* Gráfico */}
        <div className="col-lg-8 col-12">
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              {/* Título do gráfico */}
              <text
                x="50%"
                y="10"
                textAnchor="middle"
                dominantBaseline="hanging"
                fill="black"
                fontSize={18}
                fontWeight="bold"
              >
                {title}
              </text>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                labelLine={false}
                label={({ value, payload }) => {
                  if(payload.percentage) {
                    return `${value} (${payload.percentage})`
                  } else {
                    return value
                  }
                }} // Exibe os valores como rótulos
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Legenda */}
        <div className="col-lg-4 col-12 mt-3 mt-lg-0">
          <h5 className="mb-3">Legenda:</h5>
          <ul className="list-unstyled">
            {data.map((entry, index) => (
              <li key={index} className="d-flex align-items-center mb-2">
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: COLORS[index],
                    marginRight: '10px',
                  }}
                ></div>
                <span>{entry.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Total no final */}
      {/* <div className="text-center mt-4">
        <strong>Total:</strong> {totalValue}
      </div> */}
    </div>

  );
};

export default MyPieChart;
