import React, {useEffect, useState} from 'react'
import Loading from '../../Global/Loading' // Import loading component
import {getReportsValues} from '../../auth/core/_requests'
import MyBarChart from '../../../pages/Charts/TestBarChart'
import MyPieChart from '../../../pages/Charts/TestChart'

function DashboardFocal() {
  const [dataSource, setDataSource] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getFormQuestions = async () => {
      setLoading(true)
      try {
        const responseData = await getReportsValues()
        // console.log("🚀 ~ getFormQuestions ~ responseData:", responseData)
        setDataSource(responseData.data.data)
        console.log(dataSource)
      } catch (err) {
        // setError(err);
      } finally {
        setLoading(false)
      }
    }

    getFormQuestions()
  }, [])

  if (loading) return <Loading />
  if(!dataSource || dataSource.length === 0) return <h1>Erro ao carregar informação</h1>

      return  (
      <div className="d-flex row">
        <div className="col-md-6">
          <MyBarChart data={[
            {
              name: 'Educação',
              encaminhamentos: dataSource.referrals_and_returns_by_category.education.referrals,
              retornos: dataSource.referrals_and_returns_by_category.education.returns
            },
            {
              name: 'Assistência Social',
              encaminhamentos: dataSource.referrals_and_returns_by_category.social_assistance.referrals,
              retornos: dataSource.referrals_and_returns_by_category.social_assistance.returns
            },
            {
              name: 'Saúde',
              encaminhamentos: dataSource.referrals_and_returns_by_category.health.referrals,
              retornos: dataSource.referrals_and_returns_by_category.health.returns
            },
          ]}
            title='Encaminhamentos e Retornos' />

        </div>
        <div className="col-md-6">
          <MyPieChart data={[
            {
              name: 'Educação',
              value: dataSource.urgent_referrals_by_category.education
            },
            {
              name: 'Saúde',
              value: dataSource.urgent_referrals_by_category.health
            },
            {
              name: 'Assistência Social',
              value: dataSource.urgent_referrals_by_category.social_assistance
            }]}
            title='Casos urgentíssimos por área' />
        </div>
        <div className="col-md-12">
          <MyPieChart data={[
            {
              name: dataSource.main_visit_reasons[0][0].reason,
              value: dataSource.main_visit_reasons[0][0].count,
              percentage: dataSource.main_visit_reasons[0][0].percentage
            },
            {
              name: dataSource.main_visit_reasons[0][1].reason,
              value: dataSource.main_visit_reasons[0][1].count,
              percentage: dataSource.main_visit_reasons[0][1].percentage
            },
            {
              name: dataSource.main_visit_reasons[0][2].reason,
              value: dataSource.main_visit_reasons[0][2].count,
              percentage: dataSource.main_visit_reasons[0][2].percentage
            },
            {
              name: dataSource.main_visit_reasons[0][3].reason,
              value: dataSource.main_visit_reasons[0][3].count,
              percentage: dataSource.main_visit_reasons[0][3].percentage
            },
            {
              name: dataSource.main_visit_reasons[0][4].reason,
              value: dataSource.main_visit_reasons[0][4].count,
              percentage: dataSource.main_visit_reasons[0][4].percentage
            },
            {
              name: dataSource.main_visit_reasons[0][5].reason,
              value: dataSource.main_visit_reasons[0][5].count,
              percentage: dataSource.main_visit_reasons[0][5].percentage
            }]}
            title='Principais motivações para visitas' />
        </div>
      </div>
          )
}

export default DashboardFocal
