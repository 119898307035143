import React from 'react';
import { BarChart, Bar, Cell, Legend, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const data = [
  { name: 'Aprendizagem', value: 40 },
  { name: 'Saúde', value: 30 },
  { name: 'Comportamento', value: 20 },
  { name: 'Família', value: 10 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'gray'];

const MyBarChart = ({ data, title = "Chart" }) => {
  // Calculate total value
  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  return (
    <div className="container my-3">
      <div className="row">
        {/* Gráfico */}
        <div className="col-lg-8 col-12">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{ top: 50, right: 30, left: 20, bottom: 5 }}>
              {/* Título do gráfico */}
              <text
                x="5%"
                y="10"
                textAnchor="start"
                dominantBaseline="hanging"
                fill="black" // Cor do texto
                fontSize={18}
                fontWeight="bold"
              >
                {title}
              </text>

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {data.some((entry) => entry.formularios !== undefined) && (
              <Bar dataKey="formularios" fill="#8884d8">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Bar>
              )}
              {data.some((entry) => entry.encaminhamentos !== undefined) && (
              <Bar dataKey="encaminhamentos" fill="#8884d8">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Bar>
              )}
              {data.some((entry) => entry.retornos !== undefined) && (
                <Bar dataKey="retornos" fill="#82ca9d">
                  {data.map((entry, index) => (
                    <Cell key={`cell-value2-${index}`} fill={COLORS[index + data.length]} />
                  ))}
                </Bar>
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Legenda */}
        <div className="col-lg-4 col-12">
          <h5 className="mb-3">Legenda:</h5>
          <ul className="list-unstyled">
            {data.map((entry, index) => (
              <li key={index} className="d-flex align-items-center mb-2">
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: COLORS[index],
                    marginRight: '10px',
                  }}
                ></div>
                <span>{entry.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Total no final */}
      {/* <div className="text-center mt-4">
        <strong>Total:</strong> {totalValue}
      </div> */}
    </div>
  );
};

export default MyBarChart;
