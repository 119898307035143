/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
// import {useIntl} from 'react-intl'
// import {KTIcon} from '../../../../helpers'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {UserRole} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  // const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      {/* Common Menu Items */}
      <SidebarMenuItem to='/home' title='Dashboard' icon='/media/icons/cultiva/home-small.png' />

      {/* Role-Specific Menu Items */}
      {(currentUser?.role.includes(UserRole.ADMINISTRATOR) ||
        currentUser?.role.includes(UserRole.MANAGER)) && (
        <>
          {/* <h3
            style={{
              color: 'white',
              paddingLeft: '15px',
              fontWeight: 'bold',
              paddingTop: '1rem',
              borderTop: '1px solid white',
            }}
          >
            Fluxos
          </h3> */}
          <SidebarMenuItem
            to='/cadastros'
            title='Cadastros'
            icon='/media/icons/cultiva/cadastros-small.png'
          />
        </>
      )}
      {(currentUser?.role.includes(UserRole.ARTICULATOR) ||
        currentUser?.role.includes(UserRole.ADMINISTRATOR) ||
        currentUser?.role.includes(UserRole.MANAGER)) && (
        <>
          <SidebarMenuItem
            to='/articulador'
            title='Formulários'
            icon='/media/icons/cultiva/formulario-small.png'
          />
        </>
      )}
      {(currentUser?.role.includes(UserRole.CONSULTOR) ||
        currentUser?.role.includes(UserRole.ADMINISTRATOR) ||
        currentUser?.role.includes(UserRole.MANAGER)) && (
        <>
          <SidebarMenuItem to='/consultor' title='Encaminhamentos' icon='' />
        </>
      )}
      {(currentUser?.role.includes(UserRole.REVIEWER) ||
        currentUser?.role.includes(UserRole.ADMINISTRATOR) ||
        currentUser?.role.includes(UserRole.MANAGER)) && (
        <>
          <SidebarMenuItem to='/revisor' title='Encaminhamentos' icon='' />
        </>
      )}
      {(currentUser?.role.includes(UserRole.ARTICULATOR) ||
        currentUser?.role.includes(UserRole.ADMINISTRATOR) ||
        currentUser?.role.includes(UserRole.TEACHER) ||
        currentUser?.role.includes(UserRole.MANAGER)) && (
        <>
          <SidebarMenuItem to='/professor' title='Casos' icon='' />
        </>
      )}
      {currentUser?.role.includes(UserRole.FOCAL) && (
        <>
          <SidebarMenuItem to='/focal' title='Casos/Encaminhamentos' icon='' />
        </>
      )}
      {(currentUser?.role.includes(UserRole.ADMINISTRATOR) ||
        currentUser?.role.includes(UserRole.MANAGER)) && (
        <>
          <SidebarMenuItem to='/focal' title='Retornos' icon='' />
        </>
      )}
      <SidebarMenuItem
        to='/relatorio'
        title='Relatório'
        icon='/media/icons/cultiva/relatorio-small.png'
      />
    </>
  )
}

export {SidebarMenuMain}
