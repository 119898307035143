import React from 'react'
import {useAuth, UserRole} from '../auth'
import DashboardGestor from './dashboards/DashboardGestor'
import DashboardArticulador from './dashboards/DashboardArticulador'
import DashboardConsultor from './dashboards/DashboardConsultor'
import DashboardRevisor from './dashboards/DashboardRevisor'
import DashboardFocal from './dashboards/DashboardFocal'
import DashboardProfessor from './dashboards/DashboardProfessor'

const TelaInicial = () => {
  const {currentUser} = useAuth()

  console.log(currentUser)

  if (!currentUser) return <h1>Erro ao achar usuario</h1>

  return (
    currentUser && (
      <>
        <h1>Dashboard</h1>

        {(currentUser?.role[0] === UserRole.ADMINISTRATOR ||
          currentUser?.role[0] === UserRole.MANAGER) && <DashboardGestor />}
        {currentUser?.role[0] === UserRole.ARTICULATOR && <DashboardArticulador />}
        {currentUser?.role[0] === UserRole.CONSULTOR && <DashboardConsultor />}
        {currentUser?.role[0] === UserRole.REVIEWER && <DashboardRevisor />}
        {currentUser?.role[0] === UserRole.FOCAL && <DashboardFocal />}
        {currentUser?.role[0] === UserRole.TEACHER && <DashboardProfessor />}
      </>
    )
  )
}

export default TelaInicial
