import axios, {AxiosResponse} from 'axios'
import {AuthenticatedUser, serverResponse} from './_models'
import {FormStructure} from '../../formulario/newQuestions'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-authenticated-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
// form
export const GET_ALL_QUESTIONS = `${API_URL}/questions`
export const SAVE_QUESTIONS = `${API_URL}/questions/answers/save`
export const SAVE_QUESTIONS_FOR_STUDENTS = `${API_URL}/questions/answers/save`

export const GET_ALL_TEACHER_QUESTIONS = `${API_URL}/teacher/questions`
export const SAVE_TEACHER_QUESTIONS = `${API_URL}/teacher/questions/answers/save`
export const SAVE_TEACHER_QUESTIONS_FOR_STUDENT = `${API_URL}/teacher/questions/answers/save`

export const GET_ALL_REPORTS_VALUES = `${API_URL}/analytics/overview`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<serverResponse>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<AuthenticatedUser>(GET_USER_BY_ACCESSTOKEN_URL)
}

export async function getAllFormQuestions() {
  const resp = await axios.get(GET_ALL_QUESTIONS)
  return resp.data
}

export async function getAllFormAnswerQuestions(studentId: number) {
  const resp = await axios.get(GET_ALL_QUESTIONS + `/${studentId}`)
  return resp.data
}

export async function getAllTeachersFormQuestions() {
  const resp = await axios.get(GET_ALL_TEACHER_QUESTIONS)
  return resp.data
}

export async function getAllTeachersFormAnswerQuestions(stundentId: number) {
  const resp = await axios.get(GET_ALL_TEACHER_QUESTIONS + `/${stundentId}`)
  return resp.data
}

export function saveAnswers(answers: FormStructure[]) {
  return axios.post(SAVE_QUESTIONS, {
    ...answers,
  })
}
export function saveAnswersForStudent(answers: FormStructure[], studentId: number) {
  return axios.post(SAVE_QUESTIONS_FOR_STUDENTS + `/${studentId}`, {
    ...answers,
  })
}

export function saveTeachersAnswers(answers: FormStructure[]) {
  return axios.post(SAVE_TEACHER_QUESTIONS, {
    ...answers,
  })
}

export function saveTeachersAnswersForStudent(answers: FormStructure[], studentId: number) {
  return axios.post(SAVE_TEACHER_QUESTIONS_FOR_STUDENT + `/${studentId}`, {
    ...answers,
  })
}

export function getReportsValues() {
  return axios.get(GET_ALL_REPORTS_VALUES)
}
