// src/contexts/FormsProvider.js
import React, { createContext, useContext, useState } from 'react'

// Create a context
const FormsContext = createContext()

// Create the provider component
const FormsProvider = ({ children }) => {
    const [forms, setForms] = useState([]) // Initialize with an empty array

    // Function to add a new form with an auto-incremented id
    const addForm = (form) => {
        setForms((prevForms) => [
            ...prevForms,
            { ...form }, // Set id based on current length
        ])
    }

    // Function to get the current forms count
    const getFormsCount = () => forms.length

    // Function to update an existing form by ID
    const updateForm = (updatedForm) => {
        setForms((prevForms) =>
            prevForms.map((form) =>
                form.id === updatedForm.id ? updatedForm : form
            )
        );
    };

    // Value provided to components
    const value = {
        forms,
        updateForm,
        getFormsCount,
        addForm, // Allow updates to the forms array in the future
    }

    return <FormsContext.Provider value={value}>{children}</FormsContext.Provider>
}

// Custom hook to use the FormsContext in other components
const useForms = () => {
    const context = useContext(FormsContext)
    if (!context) {
        throw new Error('useForms must be used within a FormsProvider')
    }
    return context
}

export { FormsProvider, useForms }
