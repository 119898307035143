export enum UserRole {
  ADMINISTRATOR = 1,
  MANAGER = 2,
  ARTICULATOR = 3,
  CONSULTOR = 4,
  REVIEWER = 5,
  TEACHER = 6,
  FOCAL = 7,
}

export enum CategoryType {
  HEALTH = 1,
  SOCIAL_ASSISTANCE = 2,
  EDUCATION = 3,
}

export const UserRolePortuguese: Record<UserRole, string> = {
  [UserRole.REVIEWER]: 'Revisor',
  [UserRole.CONSULTOR]: 'Consultor',
  [UserRole.MANAGER]: 'Gestor',
  [UserRole.ADMINISTRATOR]: 'Administrador',
  [UserRole.ARTICULATOR]: 'Articulador',
  [UserRole.TEACHER]: 'Professor',
  [UserRole.FOCAL]: 'Ponto Focal',
}

export const CategoryTypePortuguese: Record<CategoryType, string> = {
  [CategoryType.HEALTH]: 'Saúde',
  [CategoryType.SOCIAL_ASSISTANCE]: 'Assistência Social',
  [CategoryType.EDUCATION]: 'Educação',
}

export const enum FormReferralStatus {
  PENDING_CONSULTANT_APPROVAL = 1,
  APPROVED_BY_CONSULTANT = 2,
  REJECTED_BY_CONSULTANT = 3,
  APPROVED_BY_REVIEWER = 4,
  REJECTED_BY_REVIEWER = 5,
  APPROVED = 6,
}

export const FormReferralStatusConsultor: Record<FormReferralStatus, string> = {
  [FormReferralStatus.PENDING_CONSULTANT_APPROVAL]: 'Pendente',
  [FormReferralStatus.APPROVED_BY_CONSULTANT]: 'Esperando Revisão',
  [FormReferralStatus.REJECTED_BY_CONSULTANT]: 'Esperando Revisão',
  [FormReferralStatus.APPROVED_BY_REVIEWER]: 'Aprovado',
  [FormReferralStatus.REJECTED_BY_REVIEWER]: 'Requer ajustes',
  [FormReferralStatus.APPROVED]: 'Aprovado',
}
export const FormReferralStatusReviewer: Record<FormReferralStatus, string> = {
  [FormReferralStatus.PENDING_CONSULTANT_APPROVAL]: '',
  [FormReferralStatus.APPROVED_BY_CONSULTANT]: 'Pendente',
  [FormReferralStatus.REJECTED_BY_CONSULTANT]: 'Pendente',
  [FormReferralStatus.APPROVED_BY_REVIEWER]: 'Aprovado',
  [FormReferralStatus.REJECTED_BY_REVIEWER]: 'Em revisão',
  [FormReferralStatus.APPROVED]: 'Aprovado',
}

export interface UserModel {
  id: number
  name: string
  email: string
  email_verified_at?: string
  role: UserRole[]
  cpf: string
  phone: string
  school_id: number
  category_type?: number[]
  created_at: string
  updated_at: string
  school?: School
  projects?: Project[]
}

export interface Project {
  id: number
  name: string
  description: string
  state_ibge_id: number
  state_name: string
  city_ibge_id: number
  city_name: string
}

export interface School {
  id: number
  name: string
  description: string
  state_ibge_id: number
  state_name: string
  city_ibge_id: number
  city_name: string
  project_id: number
  created_at: Date
  updated_at: Date
  projects: Project[]
}

export interface Referral {
  id: number
  name: string
  description: string
  category_type: number[]
  created_at: string
  updated_at: string
}

export interface FormReferralAction {
  id: number
  form_referral_id: number
  user_id: number
  status: FormReferralStatus
  observations: string | null
  created_at: string
  updated_at: string
}

export interface FormReferral {
  id: number
  referral_id: number
  answer_form_id: number
  created_by_user_id: number
  observations: string | null
  urgent: boolean
  referral_text: string | null
  status: number
  created_at: string
  updated_at: string
  form_referral_actions: FormReferralAction[]
  referral: Referral
  focal_return: string | null
}

export interface UrgentReason {
  id: number
  violence_signs: boolean
  hunger_signs: boolean
  depression_signs: boolean
  no_follow_up: boolean
  requires_aee: boolean
  answer_form_id: number
  created_at: string // ISO date string
  updated_at: string // ISO date string
}

export interface AnswerForm {
  id: number
  name: string
  description: string | null
  student_name: string
  school_name: string
  school_id: number
  has_form_referrals: boolean
  applied_by_user_id: number
  created_at: string
  updated_at: string
  form_referrals: FormReferral[]
  urgent_reason: UrgentReason
}

export interface Student {
  id: number
  name: string
  school_id: number
  registration: string
  grade: string
  created_at: string
  updated_at: string
}

export interface TeacherForm {
  id: number
  student_id: number
  project_id: number
  created_by_user_id: number
  form_type: string
  created_at: string
  updated_at: string
  student: Student
}

export interface FetchUserModel {
  success: boolean
  data: UserModel[]
  message: string
}

export interface serverResponse {
  success: boolean
  data: {
    token: string
    user: UserModel
  }
  message: string
}

export interface AuthenticatedUser {
  success: boolean
  data: UserModel
  message: string
}

export interface AuthModel {
  token: string
  user: UserModel
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  // updatesFromKeenthemes?: {
  //   newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
  //   tipsOnGettingMoreOutOfKeen?: boolean
  //   thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
  //   newsAboutStartOnPartnerProductsAndOtherServices?: boolean
  //   tipsOnStartBusinessProducts?: boolean
  // }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

// export interface UserModel {
//   id: number
//   username: string
//   password: string | undefined
//   email: string
//   first_name: string
//   last_name: string
//   fullname?: string
//   occupation?: string
//   companyName?: string
//   phone?: string
//   roles?: Array<number>
//   pic?: string
//   language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
//   timeZone?: string
//   website?: 'https://keenthemes.com'
//   emailSettings?: UserEmailSettingsModel
//   auth?: AuthModel
//   communication?: UserCommunicationModel
//   address?: UserAddressModel
//   socialNetworks?: UserSocialNetworksModel
// }

export interface DataSource {
  productivity_articulators: {
    forms_completed: number
    forms_not_completed: number
    pending_cases: number
  }
  forms_vs_referrals: {
    forms_applied: number
    referrals_generated: number
  }
  main_visit_reasons: Array<
    {
      reason: string
      count: number
      percentage: number
    }[]
  >
  urgent_referrals_by_category: {
    education: number
    social_assistance: number
    health: number
  }
  referrals_and_returns_by_category: {
    education: {
      referrals: number
      returns: number
    }
    social_assistance: {
      referrals: number
      returns: number
    }
    health: {
      referrals: number
      returns: number
    }
  }
}
