import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import TelaInicial from '../modules/telaInicial/TelaInicial' // Adjust import as necessary
import {useAuth, UserRole} from '../modules/auth'

// Lazy-loaded components
const Cadastros = lazy(() => import('../modules/cadastros/Cadastros'))
const DynamicForm = lazy(() => import('../modules/formulario/DynamicForm'))
const Relatorios = lazy(() => import('../modules/relatorios/Relatorios'))
const Articulador = lazy(() => import('../modules/articulador/Articulador'))
const Consultor = lazy(() => import('../modules/consultor/Consultor'))
const Revisor = lazy(() => import('../modules/revisor/Revisor'))
const EncaminhamentoConsultor = lazy(
  () => import('../modules/encaminhamento/EncaminhamentoConsultor')
)
const EncaminhamentoRevisor = lazy(() => import('../modules/encaminhamento/EncaminhamentoRevisor'))
const Professor = lazy(() => import('../modules/Professor/Professor'))
const ProfessorForm = lazy(() => import('../modules/Professor/ProfessorForm'))
const Focal = lazy(() => import('../modules/focal/Focal'))
const FocalForm = lazy(() => import('../modules/encaminhamento/EncaminhamentoFocal'))
const Casos = lazy(() => import('../modules/casos/Casos'))

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  // Map roles to allowed routes
  const routesByRole: Record<UserRole, JSX.Element[]> = {
    [UserRole.ADMINISTRATOR]: [
      <Route
        key='adm-home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-cadastros'
        path='/cadastros/*'
        element={
          <SuspensedView>
            <Cadastros />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-professor'
        path='/professor'
        element={
          <SuspensedView>
            <Professor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-professor-form'
        path='/professor-form'
        element={
          <SuspensedView>
            <ProfessorForm />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-professor-form'
        path='/professor-form/:id'
        element={
          <SuspensedView>
            <ProfessorForm />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-casos'
        path='/casos/:id'
        element={
          <SuspensedView>
            <Casos />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-focal'
        path='/focal'
        element={
          <SuspensedView>
            <Focal />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-focal-form'
        path='/encaminhamento/focal/:id'
        element={
          <SuspensedView>
            <FocalForm />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-relatorio'
        path='/relatorio'
        element={
          <SuspensedView>
            <Relatorios />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-articulador'
        path='/articulador'
        element={
          <SuspensedView>
            <Articulador />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-consultor'
        path='/consultor'
        element={
          <SuspensedView>
            <Consultor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-encaminhamento-consultor'
        path='/encaminhamento/consultor/:id'
        element={
          <SuspensedView>
            <EncaminhamentoConsultor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-encaminhamento'
        path='/encaminhamento/revisor/:id'
        element={
          <SuspensedView>
            <EncaminhamentoRevisor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-revisor'
        path='/revisor'
        element={
          <SuspensedView>
            <Revisor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-form'
        path='/formulario'
        element={
          <SuspensedView>
            <DynamicForm />
          </SuspensedView>
        }
      />,
    ],
    [UserRole.MANAGER]: [
      <Route
        key='adm-home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-cadastros'
        path='/cadastros/*'
        element={
          <SuspensedView>
            <Cadastros />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-relatorio'
        path='/relatorio'
        element={
          <SuspensedView>
            <Relatorios />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-professor'
        path='/professor'
        element={
          <SuspensedView>
            <Professor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-professor-form'
        path='/professor-form'
        element={
          <SuspensedView>
            <ProfessorForm />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-professor-form'
        path='/professor-form/:id'
        element={
          <SuspensedView>
            <ProfessorForm />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-casos'
        path='/casos/:id'
        element={
          <SuspensedView>
            <Casos />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-focal'
        path='/focal'
        element={
          <SuspensedView>
            <Focal />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-focal-form'
        path='/encaminhamento/focal/:id'
        element={
          <SuspensedView>
            <FocalForm />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-articulador'
        path='/articulador'
        element={
          <SuspensedView>
            <Articulador />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-consultor'
        path='/consultor'
        element={
          <SuspensedView>
            <Consultor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-encaminhamento-consultor'
        path='/encaminhamento/consultor/:id'
        element={
          <SuspensedView>
            <EncaminhamentoConsultor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-encaminhamento'
        path='/encaminhamento/revisor/:id'
        element={
          <SuspensedView>
            <EncaminhamentoRevisor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-revisor'
        path='/revisor'
        element={
          <SuspensedView>
            <Revisor />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-form'
        path='/formulario'
        element={
          <SuspensedView>
            <DynamicForm />
          </SuspensedView>
        }
      />,
    ],
    [UserRole.ARTICULATOR]: [
      <Route
        key='home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='articulador'
        path='/articulador'
        element={
          <SuspensedView>
            <Articulador />
          </SuspensedView>
        }
      />,
      <Route
        key='professor'
        path='/professor'
        element={
          <SuspensedView>
            <Professor />
          </SuspensedView>
        }
      />,
      <Route
        key='casos'
        path='/casos/:id'
        element={
          <SuspensedView>
            <Casos />
          </SuspensedView>
        }
      />,
      <Route
        key='formulario'
        path='/formulario'
        element={
          <SuspensedView>
            <DynamicForm />
          </SuspensedView>
        }
      />,
    ],
    [UserRole.CONSULTOR]: [
      <Route
        key='home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='consultor'
        path='/consultor'
        element={
          <SuspensedView>
            <Consultor />
          </SuspensedView>
        }
      />,
      <Route
        key='encaminhamento-consultor'
        path='/encaminhamento/consultor/:id'
        element={
          <SuspensedView>
            <EncaminhamentoConsultor />
          </SuspensedView>
        }
      />,
    ],
    [UserRole.REVIEWER]: [
      <Route
        key='home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='revisor'
        path='/revisor'
        element={
          <SuspensedView>
            <Revisor />
          </SuspensedView>
        }
      />,
      <Route
        key='encaminhamento-revisor'
        path='/encaminhamento/revisor/:id'
        element={
          <SuspensedView>
            <EncaminhamentoRevisor />
          </SuspensedView>
        }
      />,
    ],
    [UserRole.TEACHER]: [
      <Route
        key='home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='professor'
        path='/professor'
        element={
          <SuspensedView>
            <Professor />
          </SuspensedView>
        }
      />,
      <Route
        key='professor-form'
        path='/professor-form'
        element={
          <SuspensedView>
            <ProfessorForm />
          </SuspensedView>
        }
      />,
      <Route
        key='professor-form'
        path='/professor-form/:id'
        element={
          <SuspensedView>
            <ProfessorForm />
          </SuspensedView>
        }
      />,
      <Route
        key='casos'
        path='/casos/:id'
        element={
          <SuspensedView>
            <Casos />
          </SuspensedView>
        }
      />,
    ],
    [UserRole.FOCAL]: [
      <Route
        key='home'
        path='/home'
        element={
          <SuspensedView>
            <TelaInicial />
          </SuspensedView>
        }
      />,
      <Route
        key='focal'
        path='/focal'
        element={
          <SuspensedView>
            <Focal />
          </SuspensedView>
        }
      />,
      <Route
        key='adm-focal-form'
        path='/encaminhamento/focal/:id'
        element={
          <SuspensedView>
            <FocalForm />
          </SuspensedView>
        }
      />,
    ],
  }

  // Fallback route if no role matches
  // Fallback route if no role matches
  const getRoutesForRole = (roles: UserRole[]) => {
    // Check which roles are in the user's roles and return all relevant routes

    // Ensure roles is an array before calling reduce
    if (!Array.isArray(roles)) {
      return [] // If roles is not an array, return an empty array
    }
    return roles.reduce<JSX.Element[]>((routes, role) => {
      return routes.concat(routesByRole[role] || [])
    }, [])
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to /home after successful login/registration */}
        <Route path='auth/*' element={<Navigate to='/home' />} />

        {/* Render role-specific routes */}
        {currentUser?.role ? getRoutesForRole(currentUser.role) : null}

        {/* Other Routes */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
